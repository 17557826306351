import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Masters} from '../interfaces/masters.interface';
import {ConfigurationRes} from '../interfaces/responses/configuration-res.interface';
import {InitializeRes} from '../interfaces/responses/initialize-res.interface';
import {ApiUtils} from '../utils/api.utils';
import {SessionService} from './session.service';
import {SendOtpRes} from '../interfaces/responses/send-otp-res.interface';
import {TemporalPhone} from '../interfaces/responses/temporal-phone-res.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {NotificationUtils} from '../utils/notifications.utils';
import {Constants} from 'src/app/shared/constants.shared';
import {VerifyOtpRes} from '../interfaces/responses/verify-otp-res.interface';
import {MatDialog} from '@angular/material/dialog';
import {Responses} from '../shared/responses.shared';
import {IdDocType} from '../interfaces/id-doc-type.interface';
import {Bank} from '../interfaces/bank.interface';
import {sha512} from 'js-sha512';
import {StatusRes} from '../interfaces/responses/status-res.interface';
import {StepResponse} from "../interfaces/responses/step-res.interface";
import { FinalizeByUserRegistered } from '../interfaces/responses/finalize-by-existing-user-res.interface copy';
import { Country } from '../interfaces/country.interface';
import { LegalText } from '../interfaces/legal-text.interface';

@Injectable({
  providedIn: 'root'
})
export class IdentificationService {

  private _configuration?: ConfigurationRes;

  private _countries?:Country[];
  private _privacyPolicy?:LegalText;
  private _termsAndConditions?:LegalText;
  private _identityDocumentTypes?: IdDocType[]

  private _banks?: Bank[];

  private _userPhoneNumber: string | null = null;
  private _userIdentityDocument: IdDocType | null = null;
  private _userIdentityNumber: string | null = null;

  //Enviar idioma al selector de idioma
  private userLanguage = new BehaviorSubject<string>('');
  userLanguage$ = this.userLanguage.asObservable();
  shareUserLanguage(language: string) {
    this.userLanguage.next(language);
  }

  get headers() { return ApiUtils.getHeaders(); }
  get authHeaders() { return ApiUtils.getAuthHeaders(this.session.token); }

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private session: SessionService
  ) { }

  // GET CONFIGURATION

  public configuration(operationId: string): Observable<ConfigurationRes> {
    if (this._configuration) {
      return of(this._configuration);
    } else {
      const headers = new HttpHeaders({
        "Accept-Language": "es",
        "operation-id": operationId
      });
      const url = `${environment.apiBaseUrl}/configuration/web`;
      return this.http.get<ConfigurationRes>(url, { headers })
        .pipe(map(res => {
          this._configuration = res;
          return res;
        }));
    }
  }

  //GET COUNTRIES
  public countries() {
    if (this._countries) {
      return of(this._countries);
    } else {
      const url = `${environment.apiBaseUrl}/masters/countries`;
      return this.http.get<Country[]>(url, { headers: this.headers })
        .pipe(map(res => {
          this._countries = res;
          return res;
        }));
    }
  }

  //GET PRIVACY POLICY
  public privacyPolicy() {
    if (this._privacyPolicy) {
      return of(this._privacyPolicy);
    } else {
      const url = `${environment.apiBaseUrl}/legal/privacyPolicy`;
      return this.http.get<LegalText>(url, { headers: this.headers })
        .pipe(map(res => {
          this._privacyPolicy = res;
          return res;
        }));
    }
  }

  //GET TERMS AND CONDITIONS
  public termsAndConditions() {
    if (this._termsAndConditions) {
      return of(this._termsAndConditions);
    } else {
      const url = `${environment.apiBaseUrl}/legal/termsAndConditions`;
      return this.http.get<LegalText>(url, { headers: this.headers })
        .pipe(map(res => {
          this._termsAndConditions = res;
          return res;
        }));
    }
  }

  //GET DOCUMENT TYPE
    public identityDocumentTypes() {
      if (this._identityDocumentTypes) {
        return of(this._identityDocumentTypes);
      } else {
        const url = `${environment.apiBaseUrl}/masters/identityDocumentTypes`;
        return this.http.get<IdDocType[]>(url, { headers: this.headers })
          .pipe(map(res => {
            this._identityDocumentTypes = res;
            return res;
          }));
      }
    }

  // GET ALL BANK ENTITIES
  public getBankEntities() {
    if (this._banks && this._banks.length > 0) {
      return of(this._banks);
    } else {

      const headers = new HttpHeaders({
        "Accept-Language": "es",
        "operation-id": this.session.operationId!,
      });

      return this.http.get<Bank[]>(`${environment.apiBaseUrl}/bank/list`, { headers })
        .pipe(map(res => {
          this._banks = res;
          return this._banks;
        }));
    }
  }

  // INITIALIZE IDENTIFICATION PROCESS

  public initialize(operationId: string) {

    const headers = new HttpHeaders({
      "Accept-Language": "es",
      "operation-id": operationId
    });

    const url = `${environment.apiBaseUrl}/identification/initialize`;
    return this.http.get<InitializeRes>(url, { headers }).pipe(map(res => {
      this.session.token = res.accessToken;
      localStorage.removeItem("accessToken");
      localStorage.setItem("accessToken", res.accessToken);
      this.session.setUserLanguage(res.userLanguage);
      return res;
    }));

  }

  // SIGN LEGAL TEXTS

  public sign(tcVersion: number, ppVersion: number) {

    if (this.session.signedLegalTexts) return of(undefined);

    const body = {
      termsAndConditionsVersion: tcVersion,
      privacyPolicyVersion: ppVersion
    }

    const url = `${environment.apiBaseUrl}/identification/legal/sign`
    return this.http.post(url, body, { headers: this.authHeaders }).pipe(map(_ => {
      this.session.signedLegalTexts = true;
    }));

  }

  // SAVE PHONE

  public saveTemporalPhone(countryId: number, phone: string): Observable<TemporalPhone | undefined>{
    const body = { idCountry: countryId, phoneNumber: phone }
    const url = `${environment.apiBaseUrl}/identification/phone/save`

    return this.http.post<TemporalPhone>(url, body,{ headers: this.authHeaders }).pipe(map(res => {

      this.session.temporalPhone = res;

      return res;
    }));

  }


  // SEND OTP CODE

  public sendOtp(): Observable<SendOtpRes | undefined> {

    const body = {}
    const url = `${environment.apiBaseUrl}/identification/phone/sendVerificationCode`

    return this.http.post<SendOtpRes>(url, body, { headers: this.authHeaders }).pipe(map(res => {

      this.session.sendOtpRes = res;

      if (!res.sent && res.timeToResendCodeInSeconds) {

        const action = this.translate.instant("actions.accept");
        const msg = this.translate.instant("title.resend_otp_seconds").replace(Constants.LOCALISE_REPLACE_STR, res.timeToResendCodeInSeconds);

        this.snackBar.open(msg, action, NotificationUtils.snackbarConfig());

      }

      return res;

    }));

  }

  // VERIFY OTP CODE

  public verifyOtp(countryId: number, phone: string, code: string) {

    code = sha512(code);

    const body = { idCountry: countryId, phoneNumber: phone, code }
    const url = `${environment.apiBaseUrl}/identification/phone/verifyCode`

    return this.http.post<VerifyOtpRes>(url, body, { headers: this.authHeaders }).pipe(map(res => {

      this.session.verifyOtpRes = res;

      if (res.correct) {

        this.session.verifiedPhone = true;

      } else if (res.timeToRetryInSeconds && res.timeToRetryInSeconds > 0) {

        const action = this.translate.instant("actions.accept");
        const msg = this.translate.instant("title.verify_otp_seconds").replace(Constants.LOCALISE_REPLACE_STR, res.timeToRetryInSeconds);

        this.snackBar.open(msg, action, NotificationUtils.snackbarConfig());

      } else {

        const title = res.remainingIntents === 0
          ? this.translate.instant("title.max_otp_verify_intents")
          : this.translate.instant("title.otp_incorrect_intents_remaining").replace(Constants.LOCALISE_REPLACE_STR, res.remainingIntents);

        NotificationUtils.dialog(this.dialog, title, undefined, undefined, Constants.DialogIcon.ERROR);

      }

      return res;

    }));

  }

  //GET CONTINUE eID BY WEB

  public continueByWeb() {

    const url = `${environment.apiBaseUrl}/identification/continueByWeb`;

    return this.http.get<any>(url, { headers: this.authHeaders }).pipe(map(res => {
      return res;
    }));

  }

  //GET STATUS

  public status() {

    const url = `${environment.apiBaseUrl}/identification/status`;

    return this.http.get<StatusRes>(url, { headers: this.authHeaders }).pipe(map(res => {
      return res;
    }));

  }

  //FinalizeByUserRegistered
  public finalizeByUserRegistered(): Observable<FinalizeByUserRegistered | undefined>{
    const url = `${environment.apiBaseUrl}/identification/finalizeByApp`
    const requestBody = {}; 
    return this.http.post<FinalizeByUserRegistered>(url, requestBody,{ headers: this.authHeaders }).pipe(map(res => {
      //this.session.temporalPhone = res;
      return res;
    }));

  }

  public handleVerifyCodeResError(res: HttpErrorResponse) {
    if (res.status === 400) {
      if (res.error.errorCode === Responses.VerifyOtpResError.CODE_EXPIRED) {
        NotificationUtils.dialog(this.dialog, "title.otp_expired", undefined, undefined, Constants.DialogIcon.ERROR);
      } else {
          NotificationUtils.dialog(this.dialog, "title.error_try_later", undefined, undefined, Constants.DialogIcon.ERROR);
      }
    } else {
      NotificationUtils.dialog(this.dialog, "title.error_try_later", undefined, undefined, Constants.DialogIcon.ERROR);
    }
  }

  // SEND IDENTIFICATION DOCUMENT

  sendIdDoc(idDocType: IdDocType, idDoc: string) {

    const body = { idIdentityDocumentType: idDocType.id, identityDocument: idDoc };
    const url = `${environment.apiBaseUrl}/identification/identityDocument/send`;

    return this.http.post(url, body, { headers: this.authHeaders }).pipe(map(res => {

      this.session.idDoc = idDoc;
      this.session.idDocType = idDocType;

      this.session.sentIdDoc = true;

      return res;

    }));

  }

  // OBTAIN BANK URL

  bankAuthorization(idBank: number, useCase: string) {
    // api-psd2/v1/bank-integration/init?use-case=IDENTIFICATION_APP&bank-id=30
    const url = `${environment.apiPsd2BaseUrl}/bank-integration/init`;
    const params = {"use-case": useCase, "bank-id": idBank};
    // const url = `${environment.apiBaseUrl}/identification/bank/authorization`;
    return this.http.post<StepResponse>(url, { idBank }, { headers: this.authHeaders, params: params });
  }

  executeNextStep(transactionId: string, nextStep:string){
    const url = `${environment.apiPsd2BaseUrl}/bank-integration/execute/` + transactionId;
    const params = {"next-step": nextStep}
    return this.http.get<StepResponse>(url, { headers: this.authHeaders, params: params });
  }

  middlewareCallback(params: { [key: string]: string }) {
    const url = `${environment.apiPsd2BaseUrl}/bank-integration/middleware`;
    return this.http.get<StepResponse>(url, { headers: this.authHeaders, params });
  }
  

  // OBTAIN OPERATION ACCEPT OR REJECT URLs

  acceptUrl(bankId: number) {
    return `${environment.apiBaseUrl}/operation/bank-identification/accept?bankAccount=${bankId}`;
  }

  rejectUrl() {
    return `${environment.apiBaseUrl}/operation/bank-identification/reject`;
  }

  // OBTAIN THE DATA UPDATED BY THE USER ON EID
  set userPhoneNumber(phone: string | null) {
    this._userPhoneNumber = phone;
  }
  get userPhoneNumber(): string | null {
    return this._userPhoneNumber;
  }
  set userIdentityDocument(document: IdDocType | null) {
    this._userIdentityDocument = document;
  }
  get userIdentityDocument(): IdDocType | null {
    return this._userIdentityDocument;
  }
  set userIdentityNumber(documentNumber: string | null) {
    this._userIdentityNumber = documentNumber;
  }
  get userIdentityNumber(): string | null {
    return this._userIdentityNumber;
  }

}
