import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {IdentificationService} from "../../services/identification.service";
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrls: ['./middleware.component.scss']
})
export class MiddlewareComponent implements OnInit {

  state: string | undefined;
  code: string | undefined;
  error: string | undefined;
  middlewareLoading: boolean = true;
  alertMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private identificationService: IdentificationService) {
  }

  ngOnInit(): void {
    this.getRouteParams();
    this.validateParams();
  }

  getRouteParams(){
    this.state = this.route.snapshot.queryParams["state"];
    this.code = this.route.snapshot.queryParams["code"];
    this.error = this.route.snapshot.queryParams["error"];
  }

  validateParams(): void {
    if (this.error || (!this.state && !this.code)) {
      this.alertMessage =
        'Petición errónea, no se puede continuar con el proceso. Cierra la pestaña. Si el error persiste, ponte en contacto con la empresa con la que has iniciado el proceso de identificación.';
    } else if (this.state && this.code) {
      this.alertMessage = 'Te estamos redirigiendo para gestionar los consentimientos.';
    } else if (this.state) {
      this.alertMessage = 'Estamos redirigiéndote al final de la operación.';
    }
    this.middlewareCallback();
  }
  

  middlewareCallback(): void {
    const params: { [key: string]: string } = {
      ...(this.code ? { code: this.code } : {}),
      ...(this.state ? { state: this.state } : {}),
      ...(this.error ? { error: this.error } : {})
    };
  
    this.identificationService.middlewareCallback(params)
      .subscribe({
        next: stepResponse => {
          if (stepResponse.redirect) {
            window.location.replace(stepResponse.redirectUrl);
          }else if(!stepResponse.executionResult){
            this.alertMessageError(stepResponse.error);
          }

        },
        error: response => {
          this.alertMessageError(response.error.error);
        }
      });
  }

  alertMessageError(message?: string): void {
    this.middlewareLoading = false;
    this.alertMessage = message || 'Petición errónea, no se puede continuar con el proceso. Cierra la pestaña. Si el error persiste, ponte en contacto con la empresa con la que has iniciado el proceso de identificación.';
  }
  

}
