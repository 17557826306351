import {Component} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DocumentDialogComponent} from './document-dialog/document-dialog.component';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {IdentificationService} from 'src/app/services/identification.service';
import {Constants} from 'src/app/shared/constants.shared';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Country} from 'src/app/interfaces/country.interface';
import {CountryDialogComponent} from '../../shared/country-dialog/country-dialog.component';
import ModelUtils from 'src/app/utils/model.utils';
import {FormUtils} from 'src/app/utils/form.utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent {

  popup: boolean = false;
  loading: boolean = false;

  page: number = 1;
  totalPages: number = 0;

  document: string = "https://assets.website-files.com/603d0d2db8ec32ba7d44fffe/603d0e327eb2748c8ab1053f_loremipsum.pdf";

  privacyPolicy!: SafeHtml;
  termsAndConditions!: SafeHtml;

  selected?: Country;
  countries: Country[] = [];

  tcVersion?: number;
  ppVersion?: number;

  form: FormGroup = this.formBuilder.group({
    read: [false, [Validators.requiredTrue]],
    termsAndConditions: [false, [Validators.requiredTrue]],
    privacyPolicy: [false, [Validators.requiredTrue]],
  });

  get logo() { return this.session.logo; }

  get valid() {

    if (this.selected == undefined) return false;

    if (this.popup && this.form.controls["phone"]) {
      const fullPhone = `${this.selected.phoneCode}${this.form.controls["phone"].value}`;
      if (!FormUtils.validRegex(this.selected.phoneValidationRegex, fullPhone)) return false;
    }

    return this.form.valid;

  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private session: SessionService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private identification: IdentificationService,
  ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {

    this.identification.privacyPolicy().subscribe(res => {
      const privacyText = this.translate.instant("title.accept_policy").replace(Constants.LOCALISE_REPLACE_STR, res.url);
      this.ppVersion = res.version;
      this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(privacyText);
     });

     this.identification.termsAndConditions().subscribe(res => {
      const termsText = this.translate.instant("title.accept_terms").replace(Constants.LOCALISE_REPLACE_STR, res.url);
      this.tcVersion = res.version;
      this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(termsText);
     });

     this.identification.countries().subscribe(res => {
      this.countries = res;
      const defaultCountry = ModelUtils.getDefaultCountry(res);
      if (defaultCountry) this.selected = defaultCountry;
     });

     if (this.popup) {

      this.form.addControl("phone", new FormControl("", Validators.required));
      this.form.addControl("country", new FormControl("", Validators.required));

      if (this.selected) this.form.controls["country"].setValue(this.selected.id);

      if(this.session.phone) this.form.controls["phone"].setValue(this.session.phone);

      if(this.session.country) {
        this.selected = ModelUtils.getCountryById(this.countries, this.session.country.id);
        this.form.controls["country"].setValue(this.session.country?.id);
      }

    }

    this.form.controls["read"].setValue(this.session.readDoc);
    this.form.controls["termsAndConditions"].setValue(this.session.signedLegalTexts);
    this.form.controls["privacyPolicy"].setValue(this.session.signedLegalTexts);

  }

  scroll() {
    this.page = this.totalPages;
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  pageChange(current: number) {
    this.page = current;
    if (this.page === this.totalPages && !this.form.controls["read"].value)
      this.form.controls["read"].setValue(true);
  }

  fullscreen() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = "100vh";
    dialogConfig.maxWidth = "100vw";
    dialogConfig.height = "100%";
    dialogConfig.width = "100%";

    dialogConfig.data = {
        document: this.document,
        logo: this.logo,
        page: this.page
    };

    const dialogRef = this.dialog.open(DocumentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(page => {
      if (page) this.pageChange(page);
    });

  }

  extensions() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = "countries-dialog";
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    if (this.selected) dialogConfig.data = { selected: this.selected.id };

    const dialogRef = this.dialog.open(CountryDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selected = result;
        this.form.controls["country"].setValue(result.id);
      }
    });

  }

  clearPhone() {
    this.form.controls["phone"].setValue("");
  }

  submit() {

    if (!this.valid || !this.selected || !this.tcVersion || !this.ppVersion) return;

    this.loading = true;

    this.signLegalTexts(() => this.sendOtp(() => {
      this.session.readDoc = true;
      if (this.popup) {
        this.router.navigate([Constants.Path.VERIFY_PHONE], { queryParams: this.session.operationIdQueryParam() });
      } else {
        this.router.navigate([Constants.Path.PHONE], { queryParams: this.session.operationIdQueryParam() });
      }
    }));

  }

  signLegalTexts(callback: () => void) {
    this.identification.sign(this.tcVersion!, this.ppVersion!).subscribe({
      next: _ => callback(),
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    });
  }

  sendOtp(callback: () => void) {

    if (this.popup) {

      const phone = this.form.controls["phone"].value;

      this.identification.sendOtp().subscribe({
      // this.identification.sendOtp(this.selected!.id, phone).subscribe({
        next: res => {

          if (!res || (res && !res.sent)) return;

          this.session.phone = phone;
          this.session.country = this.selected!;

          this.session.verifiedPhone = false;

          callback();

        }, error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
      }).add(() => this.loading = false);

    } else {
      callback();
    }

  }

  getErrorMessage() {
    return this.session.error;
  }
}
