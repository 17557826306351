import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../shared/constants.shared';
import { Bank } from '../../interfaces/bank.interface';
import { SessionService } from '../../services/session.service';
import { IdentificationService } from 'src/app/services/identification.service';
import jwtDecode from 'jwt-decode';
import { flatMap } from 'rxjs';

@Component({
  selector: 'select-bank-page',
  templateUrl: './select-bank.component.html'
})
export class SelectBankComponent {

  banks?: Bank[];

  search: string = "";

  inputDocument: boolean= false;

  get filteredBanks() {
    if (!this.banks) return [];
    return this.banks.filter(bank => {
      const name = bank.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const search = this.search.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");  
      return name.includes(search);
    }).sort((a, b) => (a.name > b.name) ? 1 : -1);
  }
  
  get mostUsedBanks() {
    if (!this.banks) return [];
    const mostUsed = this.banks.filter(b => b.order); 
    return mostUsed.sort((a, b) => a.order > b.order ? 1 : -1);
  }

  constructor(
    private router: Router,
    private session: SessionService,
    private identification: IdentificationService, 
  ) {}

  ngOnInit() {
    this.identification.getBankEntities().subscribe({
      next: res => {
        this.banks = res;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(_: any) {
    return false;
  }

  select(bank: Bank) {

    this.session.bank = bank;
    
    this.router.navigate([Constants.Path.PERMISSIONS], { queryParams: this.session.operationIdQueryParam() });

  }

  back() {
    this.inputDataCheck();
    if(this.inputDocument){
      this.router.navigate([Constants.Path.PHONE], { queryParams: this.session.operationIdQueryParam() })
    }else{
      this.router.navigate([Constants.Path.IDENTITY_DOC], { queryParams: this.session.operationIdQueryParam() });
    }    
  }

  inputDataCheck(): void {
      const token = localStorage.getItem("accessToken");
     
      if (token) {
        try {
          const decodedToken = jwtDecode<{ phoneNumber?: boolean; identityDocument?: boolean }>(token);
      
          this.inputDocument= decodedToken.identityDocument === true; 
      
          console.log("phoneHidden:", this.inputDocument);
      
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    }

}
