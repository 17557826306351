import { Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SignatureRes } from 'src/app/interfaces/responses/signature-res.interface';
import { IdentificationService } from 'src/app/services/identification.service';
import { OperationService } from 'src/app/services/operation.service';
import { SessionService } from 'src/app/services/session.service';
import { Constants } from 'src/app/shared/constants.shared';
import { GlobalUtils } from 'src/app/utils/global.utils';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html'
})
export class SignatureComponent {

  loading: boolean = false;

  operationData?: SignatureRes;

  privacyPolicy?: SafeHtml;

  constructor(
    private masters: IdentificationService,
    private session: SessionService,
    private operation: OperationService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.fetch(this.session.operationId!);
  }

  fetch(operationId: string) {

    this.loading = true;
    this.operation.signature(operationId).subscribe({
      next: res => {
        this.operationData = res;
        if (res.serviceProviderData.styleUrl) GlobalUtils.loadCss(document, res.serviceProviderData.styleUrl);
        if (res.serviceProviderData.faviconUrl) GlobalUtils.loadFavicon(res.serviceProviderData.faviconUrl);
        GlobalUtils.loadCss(document, res.serviceProviderData.styleUrl);
        GlobalUtils.setTabTitle(this.translate.instant("actions.sign"));
      },
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    }).add(() => this.loading = false);

    this.masters.privacyPolicy().subscribe(res => {
      this.privacyPolicy = res.url;
    });

  }

  submit(action: "accept" | "reject") {}

}
