import {Component, OnInit, ViewChild} from '@angular/core';
import {Constants} from 'src/app/shared/constants.shared';
import {Country} from 'src/app/interfaces/country.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../utils/form.utils';
import {SessionService} from '../../services/session.service';
import {Router, ActivatedRoute} from '@angular/router';
import ModelUtils from 'src/app/utils/model.utils';
import {TranslateService} from '@ngx-translate/core';
import {IdentificationService as IdentificationService} from '../../services/identification.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CountryDialogComponent} from 'src/app/shared/country-dialog/country-dialog.component';
import {HttpClient} from '@angular/common/http';
import {TemporalPhone} from "../../interfaces/responses/temporal-phone-res.interface";
import jwtDecode from "jwt-decode";
import { DecodedToken } from 'src/app/interfaces/responses/decodedToken';
import { FinalizeByUserRegistered } from 'src/app/interfaces/responses/finalize-by-existing-user-res.interface copy';

@Component({
  selector: 'phone-page',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  accessTokenLoaded: boolean = false; 

  _country: Country | undefined = undefined;

  _countries: Country[] = [];

  _valid: boolean = false;
  _loading: boolean = false;

  privacyPolicy!: SafeHtml;
  termsAndConditions!: SafeHtml;

  tcVersion?: number;
  ppVersion?: number;

  tcUrl?: string;
  ppUrl?: string;

  //userLanguage
  options: { value: string, label: string }[] = [];
  languageCode:string |undefined;
  datos: { [key: string]: string } = {};

  userPhoneNumber: string | null = null;
  userIdentityDocumentType: string | null = null;
  userIdentityDocument: string | null = null;

  phoneHidden: boolean = false;

  form: FormGroup = this.formBuilder.group({
    countryId: [, [Validators.required]],
    phone: ["", [Validators.required]],
    tcChecked: [false, Validators.requiredTrue],
    ppChecked: [false, Validators.requiredTrue]
  });

  @ViewChild("phoneInput", { static: false }) phoneInput: any;

  get logo() { return this.session.logo; }

  set loading(value: boolean) {
    this._loading = value;
    FormUtils.enableForm(this.form, !value);
  }

  set country(country: Country | undefined) {
    this._country = country;
    this.form.controls["countryId"].setValue(country ? country.id : undefined);
  }

  get country() { return this._country; }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private session: SessionService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private identification: IdentificationService,
    private http: HttpClient,
  ) {
    this.translate.onLangChange.subscribe(() => this.refreshLanguage());
  }

  ngOnInit() {
    this._loading = true
    const operation = this.route.snapshot.queryParams["operationId"];
    this.identification.initialize(operation).subscribe(() => {
      this.inputDataCheck();
    });
    this.fetchCountries();
    this.initializeLegalTexts();
    this.loadSessionLanguage();
    this.form.valueChanges.subscribe(_ => this.validate());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.phoneInput) this.phoneInput.nativeElement.focus();
    });
  }

  fetchCountries() {
    this.identification.countries().subscribe({
      next: res => {
        this._countries = res;
        const defaultCountry = ModelUtils.getDefaultCountry(res);
        if (defaultCountry) this.country = defaultCountry;
      }
    });
  }

  inputDataCheck(): void {
    const token = localStorage.getItem("accessToken");
  
    if (token) {
      try {
        const decodedToken = jwtDecode<{ phoneNumber?: boolean; identityDocument?: boolean }>(token);
        this.phoneHidden = decodedToken.phoneNumber === true; 
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    this._loading = false;
  }
  

  initializeLegalTexts() {

    this.identification.privacyPolicy().subscribe(res => {
      this.ppUrl = res.url;
      this.ppVersion = res.version;
      const privacyText = this.translate.instant("title.accept_policy",{'url': this.ppUrl});
      this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(privacyText);
    });

    this.identification.termsAndConditions().subscribe(res => {
      this.tcUrl = res.url;
      this.tcVersion = res.version;
      const termsText = this.translate.instant("title.accept_terms",{'url': this.tcUrl});
      this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(termsText);
    });

  }

  refreshLanguage(){
    this.translate.stream("title.accept_policy",{'url': this.ppUrl}).subscribe((privacyText) => {
      this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(privacyText);
    });
    this.translate.stream("title.accept_terms",{'url': this.tcUrl}).subscribe((termsText) => {
      this.termsAndConditions = this.sanitizer.bypassSecurityTrustHtml(termsText);
    });
  }

  validate() {

    if (!this.form.controls["tcChecked"].value || !this.form.controls["ppChecked"].value) {
      this._valid = false;
      return;
    }

    if (this.phoneHidden) {
      this._valid = true;
      return;
    }

    if (this.country == undefined) {
      this._valid = false;
      return;
    }

    const fullPhone = `${this.country.phoneCode}${this.form.controls["phone"].value}`;
    this._valid = this.form.valid && this.country != undefined && FormUtils.validRegex(this.country.phoneValidationRegex, fullPhone);
  }

  submit() {

    if (!this._valid || !this.country || !this.tcVersion || !this.ppVersion) return;

    this.loading = true;
    this.signLegalTexts(() => {
      if (this.phoneHidden) {
        // Si el teléfono está oculto, ir directo al siguiente paso
        this.finalizeByExistingUser();
        return;
      }
      // Si el teléfono está visible, seguir flujo normal
      const phone = this.form.controls["phone"].value;
      this.identification.userPhoneNumber = phone;
      this.saveTemporalPhone();
    });
  }

  signLegalTexts(callback: () => void) {
    this.identification.sign(this.tcVersion!, this.ppVersion!).subscribe({
      next: _ => callback(),
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    });
  }

  saveTemporalPhone() {
    const phone = this.form.controls["phone"].value;
    this.session.phone = phone;
    this.session.country = this.country!;
    this.identification.saveTemporalPhone(this.country!.id, phone).subscribe({
      next: data => this.checkResponse(data),
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    });

    this.loading = false;
  }

  finalizeByExistingUser(){
    this.identification.finalizeByUserRegistered().subscribe({
      next: data => this.checkUserRegisteredResponse(data),
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    })
    this.loading =false;
  }

  checkResponse(temporalPhoneResponse: TemporalPhone | undefined){
    
    if (temporalPhoneResponse?.userRegistered) {
      this.router.navigate([Constants.Path.FINALIZE_APP]);
    }
    else {
      this.router.navigate([Constants.Path.VERIFY_PHONE]);
    }
  }

  checkUserRegisteredResponse(finalizeByExistingUser: FinalizeByUserRegistered | undefined){
    
    if (finalizeByExistingUser?.userRegistered) {
      this.router.navigate([Constants.Path.FINALIZE_APP]);
    }
    else {
      this.session.verifiedPhone = true;
      this.router.navigate([Constants.Path.IDENTITY_DOC]);
    }
  }

  clearPhone() {
    this.form.controls["phone"].setValue("");
  }

  select(country: Country) {
    this.country = country;
  }

  toggle() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = "countries-dialog";
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    if (this.country) dialogConfig.data = { selected: this.country.id };

    const dialogRef = this.dialog.open(CountryDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.country = result;
    });

  }

  loadSessionLanguage() {
    this.session.$userLanguage.subscribe(language=>{
      this.http.get('assets/i18n/locale.properties', { responseType: 'text' }).subscribe({
        next:(data: string) => {
          this.datos = this.parseProperties(data);
          this.languageCode = this.obtenerClave(language) || 'es-ES';
          this.translate.use(this.languageCode);
        },
        error:(error) => {
          console.error('Error al cargar las propiedades', error);
        }
      });
    });
  }

    private obtenerClave(valor: string): string | undefined {
      return Object.keys(this.datos).find(clave => this.datos[clave] === valor.toUpperCase());
    }

    private parseProperties(data: string): { [key: string]: string } {
      return data
        .split('\n')
        .filter(line => line.trim() !== '')
        .reduce((acc, line) => {
          const [clave, valor] = line.split('=');
          if (clave && valor) {
            acc[clave.trim()] = valor.trim();
          }
          return acc;
        }, {} as { [key: string]: string });
    }
}

